<template>
  <section class="inventoryproducts-products">
    <Product
      v-for="product in products"
      :key="product._id"
      :product="product"
      @add="setActiveProduct(product)"
    />
  </section>
</template>

<script>
import Product from "./Product";
export default {
  props: ["products"],
  components: {
    Product
  }
};
</script>

<style scoped lang="scss">
.inventoryproducts-products {
  @include grid($cols: 1fr, $col-gap: 50px, $row-gap: 50px);
  height: fit-content;
}
@media (min-width: $lg) {
  .inventoryproducts-products {
    grid-template-columns: 1fr 1fr;
  }
}
</style>