<template>
  <download-excel
    :data="data"
    :name="`${this.fileName}.csv`"
    type="csv"
    class="mt-15 btn btn__sm btn__green"
  >Current Inventory</download-excel>
</template>

<script>
export default {
  props: ["data", "fileName"]
};
</script>