<template>
  <section class="bulkitem-sidebar shadow pos__rel">
    <div class="bulkitem-sidebar--exit flex__center pointer" @click="unsetActiveProduct">
      <font-awesome-icon icon="times" size="lg" />
    </div>
    <simplebar class="sidebar--overflow" data-simplebar-auto-hide="false">
      <div class="bulkitem-sidebar__grid">
        <div class="flex__between">
          <h2 class="mr-10">{{ product.name }}</h2>
          <div class="resuts-product--img__div flex__center">
            <div
              v-if="product.images.length <= 0"
              class="results-product--img__default flex__center text__center text__sm"
            >No product image present</div>
            <img
              v-else
              class="results-product--img"
              :src="product.images[0]"
              :alt="`Product image for ${product.name}`"
            />
          </div>
        </div>
        <Add :product="product" :user="user" />
      </div>
    </simplebar>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Add from "./Add";
export default {
  props: ["product", "user"],
  components: {
    Add
  },
  methods: {
    ...mapActions(["startUnsetActiveProduct"]),
    unsetActiveProduct() {
      this.startUnsetActiveProduct(null);
    }
  }
};
</script>

<style scoped lang="scss">
$dark-frost: darken($frost, 35%);
.bulkitem-sidebar {
  @include pos-abs(
    $pos: fixed,
    $top: 0,
    $left: null,
    $right: 0,
    $width: calc(100% - 40px),
    $height: 100vh,
    $z: 15
  );
  max-width: 450px;
  background-color: $frost;
  padding: 25px;
  padding-right: 15px;
}
.bulkitem-sidebar--exit {
  @include pos-abs($pos: absolute, $top: 3px, $left: null, $right: 100%);
  background-color: $dark-frost;
  padding: 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: $frost;
}
.sidebar--overflow {
  max-height: calc(100% - 10px);
}
.bulkitem-sidebar__grid {
  @include grid($cols: 1fr, $row-gap: 20px);
  padding-right: 20px;
}
.results-product--img,
.results-product--img__default {
  width: 100px;
  height: 100px;
}
</style>