<template>
  <div class="inventory-products--filters filters tile">
    <div class="flex__between">
      <h2>Filters</h2>
      <span class="breadcrumb" @click="reset">Reset</span>
    </div>
    <div class="flex__between m-tb-sm">
      <button class="btn btn__sm btn__red" @click="emitToggleFilters">Exit</button>
      <button class="btn btn__sm btn__frost" @click="submit">Submit</button>
    </div>
    <simplebar class="filters--overflow">
      <div class="inventory-products--filters__grid">
        <div class="permissions--select flex__between pointer" @click="toggleProducts">
          <span>Order Product</span>
          <span>
            <font-awesome-icon v-if="!productsToggled" icon="caret-down" size="sm" class="ml-10" />
            <font-awesome-icon v-else icon="caret-up" size="sm" class="ml-10" />
          </span>
        </div>
        <input v-if="productsToggled" type="text" v-model="search" placeholder="product search" />
        <simplebar class="major--overflow" data-simplebar-auto-hide="false">
          <ul v-if="productsToggled" class="permissions--list">
            <li v-for="product in filteredProducts" :key="product._id">
              <label>
                <input type="checkbox" :value="product._id" v-model="form.products" />
                <span class="text__overflow">{{ product.name }}</span>
              </label>
            </li>
          </ul>
        </simplebar>
        <label for="#">
          Product Status
          <ul class="checkbox-status">
            <li>
              <label>
                <input type="checkbox" value="live" v-model="form.status" />
                Live
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" value="coming-soon" v-model="form.status" />
                Coming Soon
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" value="test" v-model="form.status" />
                test
              </label>
            </li>
          </ul>
        </label>
        <div>
          <div class="categories--select flex__between" @click="toggleCategories">
            <span>Categories</span>
            <span>
              <font-awesome-icon v-if="categoriesToggled" icon="caret-up" size="sm" class="ml-10" />
              <font-awesome-icon v-else icon="caret-down" size="sm" class="ml-10" />
            </span>
          </div>
          <ul v-if="categoriesToggled" class="categories--list">
            <li v-for="category in getCategories" :key="category._id">
              <label>
                <input type="checkbox" :value="category._id" v-model="form.categories" />
                {{ category.name }}
              </label>
            </li>
          </ul>
        </div>
        <label>
          Quantity Min
          <input type="number" v-model="form.quantMin" placeholder="0" />
        </label>
        <label>
          Quantity Max
          <input type="number" v-model="form.quantMax" placeholder="0" />
        </label>
        <label>
          Export Start Date
          <input
            type="date"
            v-mask="'####-##-##'"
            v-model="form.dateStart"
            placeholder="yyyy-mm-dd"
          />
        </label>
        <label>
          Export End Date
          <input
            type="date"
            v-mask="'####-##-##'"
            v-model="form.dateEnd"
            placeholder="yyyy-mm-dd"
          />
        </label>
      </div>
    </simplebar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["filters"],
  data() {
    return {
      categoriesToggled: false,
      search: "",
      productsToggled: false,
      form: {
        products: this.filters ? this.filters.products : [],
        status: this.filters ? this.filters.status : [],
        categories: this.filters ? this.filters.categories : [],
        quantMin: this.filters ? this.filters.quantMin : null,
        quantMax: this.filters ? this.quantMax : null,
        dateStart: this.filters ? this.filters.dateStart : null,
        dateEnd: this.filters ? this.filters.dateEnd : null
      }
    };
  },
  watch: {
    filters: {
      handler: function(newVal) {
        this.form = JSON.parse(JSON.stringify(newVal));
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["getCategories", "getProducts"]),
    filteredProducts() {
      if (!this.search) return this.getProducts;
      return this.getProducts.filter(product => {
        return product.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    ...mapActions(["startSetInventoryFilters", "startUnsetInventoryFilters"]),
    toggleProducts() {
      this.productsToggled = !this.productsToggled;
    },
    toggleCategories() {
      this.categoriesToggled = !this.categoriesToggled;
    },
    emitToggleFilters() {
      this.$emit("toggleFilters");
    },
    submit() {
      this.startSetInventoryFilters(this.form);
      this.$emit("resetPage");
      this.emitToggleFilters();
    },
    reset() {
      this.startUnsetInventoryFilters();
      this.$emit("resetPage");
      this.emitToggleFilters();
    },
    setProducts() {
      if (this.$route.query && this.$route.query.product) {
        this.form.products.push(this.$route.query.product);
      }
    }
  },
  mounted() {
    this.setProducts();
    this.startSetInventoryFilters(this.form);
  }
};
</script>

<style scoped lang="scss">
.inventory-products--filters__grid {
  @include grid($cols: 1fr, $col-gap: 5px, $row-gap: 10px);
}
.inventory-products--filters__grid > label {
  @include grid($rows: 25px 1fr);
  font-weight: bold;
}
.checkbox-status {
  padding-left: 8px;
  @include grid($cols: 1fr, $row-gap: 3px);
  label {
    @include grid($cols: 20px 1fr, $col-gap: 15px, $align: center);
    font-weight: normal;
  }
}
.permissions--select {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid $black;
  border-radius: 5px;
  background-color: $frost;
}
.permissions--list {
  padding: 10px;
  @include grid($cols: 1fr, $row-gap: 10px);
}
.permissions--list li label {
  @include grid($cols: 20px 1fr, $col-gap: 15px, $align: center);
}
.major--overflow {
  max-height: 150px;
}
.categories--select {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid $black;
  border-radius: 5px;
  background-color: $frost;
  font-weight: bold;
}
.categories--list {
  padding: 10px;
  @include grid($cols: 1fr, $row-gap: 10px);
}
.categories--list li label {
  @include grid($cols: 20px 1fr, $col-gap: 15px, $align: center);
}
@media (min-width: $md) {
  .inventory-products--filters__grid {
    grid-column-gap: 25px;
  }
  .btn__red {
    display: none;
  }
}
</style>