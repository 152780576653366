<template>
  <section class="inventoryproducts">
    <Header @toggleFilters="toggleFilters" @toggleExport="toggleExport" />
    <section class="container">
      <div class="inventoryproducts__grid margin__md">
        <transition name="filter-slide">
          <Filters
            v-if="filtersToggled"
            @toggleFilters="toggleFilters"
            @sendFilters="setFilters"
            class="small--filters"
            :filters="filters"
          />
        </transition>
        <Filters @sendFilters="setFilters" class="large--filters" :filters="getInventoryFilters" />
        <Products :products="getFilteredInventoryProducts" />
      </div>
    </section>
    <transition name="slide">
      <Sidebar
        v-if="getActiveProduct"
        :product="getActiveProduct"
        :user="getUserDetails"
        :key="getActiveProduct._id"
      />
    </transition>
    <Export v-if="exportToggled" @toggleExport="toggleExport" :filters="getInventoryFilters" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import Filters from "./Filters";
import Products from "./Products";
import Sidebar from "./Sidebar";
import Export from "./Export";
export default {
  components: {
    Header,
    Filters,
    Products,
    Sidebar,
    Export
  },
  data() {
    return {
      filtersToggled: false,
      exportToggled: false
    };
  },
  metaInfo: {
    title: "Inventory | WOW"
  },
  computed: {
    ...mapGetters([
      "getInventoryFilters",
      "getFilteredInventoryProducts",
      "getUserDetails",
      "getActiveProduct"
    ])
  },
  methods: {
    toggleExport() {
      this.exportToggled = !this.exportToggled;
    },
    toggleFilters() {
      this.filtersToggled = !this.filtersToggled;
    },
    setFilters(obj) {
      this.filters = JSON.parse(JSON.stringify(obj));
    }
  }
};
</script>

<style scoped lang="scss">
$slide-speed: 0.25s;
.slide-enter {
  transform: translateX(100%);
}
.slide-enter-to {
  transform: translateX(0%);
  transition: transform $slide-speed ease-in-out;
}
.slide-leave {
  transform: translateX(0%);
}
.slide-leave-to {
  transform: translateX(100%);
  transition: transform $slide-speed ease-in-out;
}
@media (min-width: $md) {
  .inventoryproducts__grid {
    @include grid($cols: 1fr 3fr, $col-gap: 50px);
  }
}
</style>