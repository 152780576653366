<template>
  <section class="modal flex__center" @click.self="emitToggleExport">
    <div class="tile orders-export">
      <exit-button @toggleModal="emitToggleExport" />
      <p class="text__bold text__center text__md">Export Order Data</p>
      <p class="text__center">Click a button below to export the requested data</p>
      <div class="flex__center">
        <a
          href="https://cdn.fbhl.com/WOW-Assets/data/month-end-inventory-final.xlsx"
          name="Download the final inventory sheet"
          class="btn btn__sm btn__green"
        >Final Template</a>
      </div>
      <CurrentInventory v-if="currentInv" :data="currentInv" fileName="current-inventory" />
      <InventoryOut v-if="inventoryOut" :data="inventoryOut" fileName="all-inventory-out" />
      <InventoryIn v-if="inventoryIn" :data="inventoryIn" fileName="all-inventory-in" />
      <!-- <p class="text__center">Enter a date below and press enter to retreive extra data</p> -->
      <!-- <div class="search--bar">
        <input type="date" v-mask="'####-##-##'" v-model="invDate" @keypress.enter="fetchInvDate" />
        <div class="btn__search" @click="fetchInvDate">
          <font-awesome-icon icon="search" size="lg" />
        </div>
      </div>-->
      <DateInventory
        v-if="dateInv"
        :data="dateInv"
        :fileName="`${getInventoryFilters.dateStart}-inventory`"
        :date="getInventoryFilters.dateStart"
      />
      <TallyInventory
        v-if="dateTally"
        :data="dateTally"
        :fileName="`${getInventoryFilters.dateStart}-inventory-tallies`"
        :date="getInventoryFilters.dateStart"
        :end="getInventoryFilters.dateEnd"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CurrentInventory from "./CurrentInventory";
import InventoryOut from "./InventoryOut";
import InventoryIn from "./InventoryIn";
import DateInventory from "./DateInventory";
import TallyInventory from "./TallyInventory";
import { createError } from "../../../../libs/flash-messages";
export default {
  components: {
    CurrentInventory,
    InventoryOut,
    InventoryIn,
    DateInventory,
    TallyInventory
  },
  data() {
    return {
      currentInv: null,
      inventoryOut: null,
      inventoryIn: null,
      dateInv: null,
      dateTally: null
    };
  },
  computed: {
    ...mapGetters(["getInventoryFilters"])
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startGetCurrentInventory",
      "startGetInventoryOut",
      "startGetInventoryIn",
      "startGetInventoryDate",
      "startGetInventoryTallyDate"
    ]),
    emitToggleExport() {
      this.$emit("toggleExport");
    },
    async fetchInvDate() {
      if (!this.getInventoryFilters.dateStart) return;
      try {
        this.dateInv = await this.startGetInventoryDate(
          this.getInventoryFilters.dateStart
        );
        this.dateTally = await this.startGetInventoryTallyDate(
          this.getInventoryFilters.dateStart
        );
        return Promise.resolve("success");
      } catch (err) {
        return Promise.reject(err);
      }
    }
  },
  async mounted() {
    this.startSetIsSubmitting({ bool: true, heading: "Loading" });
    try {
      this.currentInv = await this.startGetCurrentInventory();
      this.inventoryOut = await this.startGetInventoryOut();
      this.inventoryIn = await this.startGetInventoryIn();
      await this.fetchInvDate();
      this.startSetIsSubmitting({ bool: false });
    } catch (err) {
      console.log(err);
      createError("Couldn't get data", err);
      this.emitToggleExport();
      this.startSetIsSubmitting({ bool: false });
    }
  }
};
</script>

<style scoped lang="scss">
.orders-export {
  width: 80%;
  max-width: 325px;
  position: relative;
  @include grid($cols: 1fr, $row-gap: 10px);
}
</style>