<template>
  <li class="confirm-products--add">
    <label :class="[errors.recipient ? 'form--error' : null]">
      Recipient*
      <input type="text" v-model="form.recipient" placeholder="recipient" />
    </label>
    <form-error :message="errors.recipient" />
    <label :class="[errors.quantity ? 'form--error' : null]">
      Quantity*
      <input type="number" v-model="form.quantity" min="1" max="1000" placeholder="15" />
    </label>
    <form-error :message="errors.quantity" />
    <label :class="[errors.itemCost ? 'form--error' : null]">
      Item Cost*
      <input
        type="number"
        v-model="form.itemCost"
        min="0.00"
        max="10000"
        placeholder="125"
      />
    </label>
    <form-error :message="errors.itemCost" />
    <label :class="[errors.invoiceCost ? 'form--error' : null]">
      Invoice Cost*
      <input
        type="number"
        v-model="form.invoiceCost"
        min="0.00"
        max="10000"
        placeholder="125"
      />
    </label>
    <form-error :message="errors.invoiceCost" />
    <label :class="[errors.purchaseDate ? 'form--error' : null]">
      Purchase Date
      <input
        type="date"
        v-mask="'####-##-##'"
        v-model="form.purchaseDate"
        placeholder="yyyy-mm-dd"
      />
    </label>
    <form-error :message="errors.purchaseDate" />
    <label>
      Upload Receipt
      <span class="text__sm">
        <a
          :href="`https://techsupportflatbranch.sharepoint.com/teams/WOWDesk/Shared%20Documents/Forms/AllItems.aspx?viewid=a678bc10%2D6d70%2D4237%2Dbd50%2D3836adee4639&id=%2Fteams%2FWOWDesk%2FShared%20Documents%2Fwow%2Dinventory`"
          target="_blank"
        >Sharepoint / WOW Desk / wow-inventory</a>
      </span>
    </label>
    <label>
      Receipt Link
      <input type="text" v-model="form.receipt" placeholder="sharepoint link" />
    </label>
    <label class="mb-15">
      Comments
      <div class="form--comments">
        <textarea v-model="form.comments" rows="5" placeholder="comments" />
        <canned @setMessage="setComments" />
      </div>
    </label>
    <div class="flex__between">
      <button class="btn btn__sm btn__red" @click="unsetActiveProduct">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </li>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["product", "user"],
  data() {
    return {
      isValidated: true,
      form: {
        id: null,
        requesterName: this.user ? this.user.displayName : null,
        requesterEmail: this.user ? this.user.email : null,
        requester2Email: this.user ? this.user.email : null,
        recipient: "WOW",
        product: this.product ? this.product._id : null,
        productName: this.product ? this.product.name : null,
        isInventoried: true,
        quantity: 0,
        purchase_date: "",
        receipt: null,
        invoiceCost: 0.0,
        itemCost: 0.0,
        isAdjustment: false,
        comments: ""
      },
      errors: {
        recipient: null,
        quantity: null,
        itemCost: null,
        invoiceCost: null,
        purchaseDate: null
      }
    };
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startCreateInventory",
      "startUnsetActiveProduct"
    ]),
    setComments(e) {
      this.form.comments = e.message;
    },
    async submit() {
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Adding" });
        try {
          await this.startCreateInventory(this.form);
          this.startSetIsSubmitting({ bool: false });
          this.unsetActiveProduct();
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.recipient = !this.form.recipient
        ? "Please enter a recipient for this inventory"
        : null;
      this.errors.quantity = isNaN(parseFloat(this.form.quantity))
        ? "Please enter a number only"
        : null;
      this.errors.itemCost =
        isNaN(parseFloat(this.form.itemCost)) ||
        parseFloat(this.form.itemCost) <= 0
          ? "Please enter a number greater than 0"
          : null;
      this.errors.invoiceCost =
        isNaN(parseFloat(this.form.invoiceCost)) ||
        parseFloat(this.form.invoiceCost) <= 0
          ? "Please enter a number greater than 0"
          : null;
      this.errors.purchaseDate =
        !this.form.purchaseDate || this.form.purchaseDate.length < 10
          ? "Please enter a full date (YYYY-MM-DD)"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    unsetActiveProduct() {
      this.startUnsetActiveProduct(null);
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-products--add {
  @include grid($cols: 1fr, $row-gap: 8px);
  label {
    @include grid($cols: 1fr, $row-gap: 3px);
    font-weight: bold;
  }
}
.add--overflow {
  height: 300px;
  padding: 10px 5px;
}
.add-product {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px);
}
@media (min-width: $sm) {
  .add-product {
    grid-template-columns: 1fr 1fr;
  }
}
</style>