<template>
  <section class="inventory-header">
    <div class="container">
      <div class="flex__between">
        <router-link to="/admin" class="link__none breadcrumb">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Admin
        </router-link>
        <button class="btn btn__sm btn__green mt-15" @click="emitToggleExport">Export</button>
      </div>
      <h1 class="text__center padding__sm">Product Inventory</h1>
      <p
        class="header--message pre-wrap"
      >Search for products below to view the current inventory. Click "Add" to add new inventory and "Adjust" to correct past Inventory In records.</p>
      <FilterButton
        class="products-header--sort mt-15"
        @toggleFilters="emitToggleFilters"
        @clearFilters="emitClearFilters"
      />
    </div>
  </section>
</template>

<script>
import FilterButton from "../../FilterButton";
export default {
  props: ["exports"],
  components: {
    FilterButton
  },
  methods: {
    emitToggleExport() {
      this.$emit("toggleExport");
    },
    emitToggleFilters() {
      this.$emit("toggleFilters");
    },
    emitClearFilters() {
      this.$emit("clearFilters");
    }
  }
};
</script>

<style scoped lang="scss">
@media (min-width: $md) {
  .products-header--sort {
    display: none;
  }
}
</style>