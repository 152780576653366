<template>
  <download-excel
    :data="filteredData"
    :name="`${this.fileName}.csv`"
    type="csv"
    class="mt-15 btn btn__sm btn__frost"
  >Inventory Out Records</download-excel>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: ["data", "fileName"],
  computed: {
    ...mapGetters(["getInventoryFilters"]),
    filteredData() {
      let records = JSON.parse(JSON.stringify(this.data));
      const filters = this.getInventoryFilters;
      if (filters.dateStart) {
        records = records.filter(record => {
          return (
            moment(record.fulfilled_date).format("MM-DD-YYYY HH:mm:ss") >=
            moment(filters.dateStart, ["YYYY-MM-DD"])
              .startOf("day")
              .format("MM-DD-YYYY HH:mm:ss")
          );
        });
      }
      if (filters.dateEnd) {
        records = records.filter(record => {
          return (
            moment(record.fulfilled_date).format("MM-DD-YYYY HH:mm:ss") <=
            moment(filters.dateEnd, ["YYYY-MM-DD"])
              .startOf("day")
              .format("MM-DD-YYYY HH:mm:ss")
          );
        });
      }
      if (filters.products.length >= 1) {
        records = records.filter(record => {
          return filters.products.includes(record.product_id);
        });
      }
      return records;
    }
  }
};
</script>