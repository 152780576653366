<template>
  <div class="inventoryproducts-products--tile tile pos__rel pointer" @click="setActiveProduct">
    <Status :status="product.status" />
    <!-- <Respa v-if="product.respaStatus !== 'employee only'" :status="product.respaStatus" /> -->
    <div class="resuts-product--img__div flex__center">
      <div
        v-if="product.images.length <= 0"
        class="results-product--img__default flex__center text__center text__sm"
      >No product image present</div>
      <img
        v-else
        class="results-product--img"
        :src="product.images[0]"
        :alt="`Product image for ${product.name}`"
      />
    </div>
    <p class="text__bold text__md text__wrap pre-wrap">{{ product.name }}</p>
    <p class="flex__left">
      <span class="mr-10 text__bold">Cost</span>
      <span class="text__md">${{ product.loCost.toFixed(2) }}</span>
    </p>
    <p class="flex__left">
      <span class="mr-10 text__bold">Inventory</span>
      <span class="text__md">{{ product.inventory }}</span>
    </p>
    <p class="flex__col">
      <span class="text__bold">Description:</span>
      <span>{{ shortDescription }}</span>
    </p>
    <div class="flex__right">
      <button class="btn btn__sm btn__frost" @click="setActiveProduct">Add Inventory</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Status from "./Status";
//import Respa from "../Products/Respa";
export default {
  props: ["product"],
  components: {
    Status
    //Respa
  },
  computed: {
    shortDescription() {
      return `${this.product.description.slice(0, 100)}${
        this.product.description.length > 100 ? "..." : ""
      }`;
    }
  },
  methods: {
    ...mapActions(["startSetActiveProduct"]),
    setActiveProduct() {
      this.startSetActiveProduct(this.product._id);
    }
  }
};
</script>

<style scoped lang="scss">
.inventoryproducts-products--tile {
  @include grid($cols: 1fr, $row-gap: 10px);
  height: 100%;
  transition: all 0.25s ease-in-out;
}
.inventoryproducts-products--tile:hover {
  @include shadow;
  background-color: lighten($frost, 10%);
}
.resuts-product--img__div {
  padding: 18px 0;
}
.results-product--img,
.results-product--img__default {
  width: 200px;
  height: 200px;
}
</style>