<template>
  <div class="result-product--status text__center" :class="bgClass">
    <font-awesome-icon :icon="icon" size="1x" class="mr-10 text__white" />
    <span class="text__white">{{ cleanStatus }}</span>
  </div>
</template>

<script>
export default {
  props: ["status"],
  computed: {
    cleanStatus() {
      if (this.status === "coming-soon") return "Coming Soon!";
      if (this.status === "test") return "Working on it";
      return "Ready to Order";
    },
    icon() {
      if (this.status === "coming-soon") return "clock";
      if (this.status === "test") return "tools";
      return "check";
    },
    bgClass() {
      if (this.status === "live") {
        return "bg-green";
      }
      return null;
    }
  }
};
</script>

<style scoped lang="scss">
.result-product--status {
  @include pos-abs($pos: absolute, $top: -5px, $left: -5px, $width: auto);
  padding: 2px 10px;
  padding-right: 25px;
  margin: 0 !important;
  clip-path: polygon(0% 0%, 100% 0, 88% 100%, 0% 100%);
  background: linear-gradient(135deg, $black, $maroon);
}
.bg-green {
  background: linear-gradient(135deg, $black, $green);
}
</style> 